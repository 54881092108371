import { Details, Summary, TroonAccessPlusProduct, TroonAccessProduct } from '@troon/ui';
import { For, Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { twJoin } from '@troon/tailwind-preset/merge';
import { TroonCardSubscriptionProductType } from '../../../../graphql';
import type { TroonCardSubscriptionProduct } from '../../../../graphql';

type Props = TroonCardSubscriptionProduct;

export function ProductInfo(props: Props) {
	return (
		<div
			class={twJoin(
				'relative overflow-hidden p-6',
				props.type === TroonCardSubscriptionProductType.TroonAccessPlus &&
					'bg-gradient-to-r from-neutral-950 to-brand-700 text-white',
			)}
		>
			<Icon
				name="logo-square"
				class="absolute -right-8 -top-12 z-0 size-56 origin-center -rotate-12 text-brand opacity-20 gradient-mask-b-10"
			/>
			<div class="relative z-0 flex flex-col gap-6 text-base">
				<p>
					<Show
						when={props.type === TroonCardSubscriptionProductType.TroonAccessPlus}
						fallback={<TroonAccessProduct class="h-8" />}
					>
						<TroonAccessPlusProduct class="h-8" current />
					</Show>
					<span class="sr-only">{props.subscriptionName}</span>
				</p>
				<p class="font-semibold">
					<Show when={props.subtotalAmount.value !== props.totalAmount.value}>
						<span class="me-2 text-4xl font-medium text-neutral-700 line-through">
							{props.subtotalAmount.displayValue}
						</span>
					</Show>
					<span class="text-4xl">{props.totalAmount.displayValue}</span>
					<Show when={props.autoRenew && !props.disclosure}>/yr</Show>
				</p>
				<Show when={props.disclosure}>{(disclosure) => <p class="text-sm font-normal">{disclosure()}</p>}</Show>

				<hr
					class={props.type === TroonCardSubscriptionProductType.TroonAccessPlus ? 'border-white/20' : 'border-neutral'}
				/>

				<Details>
					<Summary>Membership details</Summary>

					<div class="flex flex-col gap-4">
						<ul class="flex flex-col gap-2">
							<For each={props.valueProps}>
								{(value) => (
									<li class="flex flex-row items-center gap-2">
										<Icon name="check" class="shrink-0 text-xl text-brand" />
										{value}
									</li>
								)}
							</For>
						</ul>
					</div>
				</Details>
			</div>
		</div>
	);
}
